import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { AgGridReact } from '@ag-grid-community/react';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import { getTextValueForConditionHeader, isStartOrEndColumn } from '@/utilities/tableBuilderHelper.utilities';
import {
  AUTO_GROUP_COL_DEF,
  COLUMN_HEADER_ID,
  CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN,
  DEFAULT_COL_DEF,
  DEFAULT_TABLE_ROW_HEIGHT,
  MAX_CONDITION_TABLE_CAPSULE_COLUMNS,
  ROW_ID,
  SEEQ_AG_GRID_ID,
  SEEQ_ROW_INDEX,
  TableBuilderHeaderType,
} from '@/tableBuilder/tableBuilder.constants';
import {
  addScreenshotSetup,
  autoSizeColumns,
  createConditionColDefs,
  firstNonEmptyValueAggFunc,
  getAlternateConditionTextHeaderParams,
  getConditionCapsuleFieldName,
  getConditionColumnFieldName,
  getConditionTableDragColumns,
  getConditionTextHeaderParams,
  getDomLayout,
  getFullGroupedNodePath,
  getRowId,
  lastNonEmptyValueAggFunc,
  onColumnMoved,
  onRowDragEnd,
  rangeAggFunc,
  resizeAgGridHelper,
  standardDeviationAggFunc,
} from '@/tableBuilder/tableBuilderAgGrid.utilities';
import {
  AgGridApi,
  ColumnOrRowWithDefinitions,
  ConditionTableHeader,
  TableBuilderConditionAgGridProps,
} from '@/tableBuilder/tableBuilder.types';
import { GridReadyEvent, IRowNode } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { AlertWarning } from '@/trend/trendHelp/AlertWarning';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useDebounce } from '@/core/hooks/useDebounce.hook';
import { DEBOUNCE } from '@/core/core.constants';
import { useCounter } from 'rooks';
import { md5Hash } from '@/utilities/utilities';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';

export const TableBuilderConditionAgGrid: React.FunctionComponent<TableBuilderConditionAgGridProps> = (props) => {
  const {
    tableData,
    columns: conditionColumns,
    headers,
    isTransposed,
    onColumnResized,
    updateContentMeasurements,
    setAgGridElement,
    hasMoreData,
    setHideColumnHeadersWhenCopying,
    autoGroupColumn,
    handleRowGroupOpened,
    handleRowGroupChanged,
    rowGroupPaths,
    onAgGridReady,
    simpleColumns,
    contentResizeState,
  } = props;
  const { t } = useTranslation();

  const wrapperRef = useRef<any>(null);
  const [agGridApi, setAgGridApi] = useState<AgGridApi>();
  const setAgGridElementWrapper = (el: AgGridReact) => {
    if (setAgGridElement) {
      setAgGridElement(el);
    }
    if (wrapperRef.current) {
      wrapperRef.current.__AG_GRID__ = el;
    }
  };

  const gridReady = (params: GridReadyEvent) => {
    setAgGridApi({ api: params.api, columnApi: params.columnApi });
    addScreenshotSetup();
  };

  const rowNodeOutsideGridRef = useRef<IRowNode>();

  const { rows, columns } = conditionColumns;

  const showCapsuleHeaders = headers?.type !== TableBuilderHeaderType.None;
  const showTableHeaders = !!columns.find((column) => column.key === COLUMNS_AND_STATS.name.key);

  const isInteractiveContent = !_.isNil(updateContentMeasurements);

  const columnDefs = createConditionColDefs(props, showCapsuleHeaders);

  const autoSizeColumnsDebounce = useDebounce(autoSizeColumns, DEBOUNCE.MEDIUM_WITH_HEADLESS_RENDER_SUPPORT);

  // We need to generate unique ids if the order or a sort changes
  const partialId = md5Hash({
    isTransposed,
    columns: columns.map((column, index) => ({ key: column.key, sort: column.sort, index })),
    rows: rows.map((row, index) => ({ key: row.key, sort: row.sort, index })),
  });
  const { value: tableDataCounterValue, increment: incrementTableDataCounter } = useCounter(0);
  useEffect(() => {
    incrementTableDataCounter();
  }, [incrementTableDataCounter, tableData]);
  const buildNonCapsuleRowId = (columnOrHeader: ColumnOrRowWithDefinitions | ConditionTableHeader) => {
    return `${columnOrHeader.key}_${tableDataCounterValue}_${partialId}`;
  };

  const transposedRows = (): Record<string, any>[] => {
    // The column label row is embedded in the column headers, so we skip it if it is included
    const currentColumns = showTableHeaders ? columns.slice(1) : columns;
    let rowIndex = 0;
    const nonCapsuleRowData = currentColumns.map((column, columnIndexMaybeMinus1) => {
      const columnIndex = showTableHeaders ? columnIndexMaybeMinus1 + 1 : columnIndexMaybeMinus1;
      const row: Record<string, any> = {
        [COLUMN_HEADER_ID]: column.key,
        [ROW_ID]: buildNonCapsuleRowId(column),
        [SEEQ_ROW_INDEX]: rowIndex,
      };
      rowIndex += 1;
      if (showCapsuleHeaders) {
        const textHeaderProps = getConditionTextHeaderParams(column, columnIndex, props);
        row[CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN] = {
          textHeaderProps,
        };
      }
      _.forEach(tableData.headers, (header) => {
        row[header.key] = getTextValueForConditionHeader(header, column);
      });

      return row;
    });

    const capsuleRowData = tableData.capsules.map((capsule) => {
      const row: Record<string, any> = {
        [ROW_ID]: capsule.id + partialId,
        [SEEQ_ROW_INDEX]: rowIndex,
      };
      rowIndex += 1;

      // Cell for capsule start/end
      row[CONDITION_TABLE_TRANSPOSED_HEADER_COLUMN] = capsule;

      // Cells for each property or statistic for the capsule
      _.forEach(capsule.values, (value, valueIndex) => {
        const header = tableData.headers[valueIndex];
        row[header.key] = isStartOrEndColumn(header) ? capsule : value;
      });

      return row;
    });

    // This will ensure the capsule rows are always under the others
    return nonCapsuleRowData.concat(capsuleRowData);
  };

  const normalRows = (): Record<string, any>[] => {
    const rowData: Record<string, any>[] = [];
    tableData.headers.forEach((header, rowIndex) => {
      let columnIndex = 0;
      const row: Record<string, any> = {
        [ROW_ID]: buildNonCapsuleRowId(header),
      };
      const propertyOrStat = _.find(rows, {
        key: header.key,
        isPropertyOrStatColumn: true,
      });
      _.forEach(columns, (column) => {
        const fieldName = getConditionColumnFieldName(columnIndex);
        row[fieldName] =
          column.key === COLUMNS_AND_STATS.name.key
            ? {
                textHeaderProps: getAlternateConditionTextHeaderParams(
                  header,
                  column,
                  propertyOrStat,
                  rowIndex,
                  props,
                  showCapsuleHeaders,
                  false,
                ),
              }
            : getTextValueForConditionHeader(header, column);
        columnIndex++;
      });
      tableData.capsules.slice(0, MAX_CONDITION_TABLE_CAPSULE_COLUMNS).forEach((capsule, index) => {
        const fieldName = getConditionCapsuleFieldName(index);
        row[fieldName] = isStartOrEndColumn(header) ? capsule : capsule.values[rowIndex];
        columnIndex++;
      });

      row[COLUMN_HEADER_ID] = header.key;

      rowData.push(row);
    });

    return rowData;
  };

  const data = isTransposed ? transposedRows() : normalRows();

  const [lastTransposed, setLastTransposed] = useState<boolean>(!isTransposed);
  const showTable = isTransposed === lastTransposed;

  const autoSizeHelper = () => {
    resizeAgGridHelper(
      autoSizeColumnsDebounce,
      columnDefs,
      autoGroupColumn,
      isInteractiveContent,
      agGridApi,
      wrapperRef.current,
      showTable,
      setLastTransposed,
      isTransposed,
      updateContentMeasurements,
      onAgGridReady,
    );
  };

  useEffect(() => {
    const shouldAutoSize = agGridApi?.columnApi && simpleColumns.length > 0 && tableData.headers.length === rows.length;
    if (shouldAutoSize) {
      autoSizeHelper();
    }
  }, [tableData, simpleColumns, agGridApi, updateContentMeasurements, contentResizeState]);

  const totalRegularColumns = isTransposed ? 0 : tableData.capsules.length + columns.length;

  const [headerHeight, setHeaderHeight] = useState<number>();
  useEffect(() => {
    const hideHeaderRow = (!showTableHeaders && isTransposed) || (!showCapsuleHeaders && !isTransposed);
    setHideColumnHeadersWhenCopying && setHideColumnHeadersWhenCopying(hideHeaderRow);
    setHeaderHeight(hideHeaderRow ? 0 : undefined);
  }, [showTableHeaders, isTransposed, showCapsuleHeaders, setHideColumnHeadersWhenCopying]);

  return (
    <>
      {totalRegularColumns > MAX_CONDITION_TABLE_CAPSULE_COLUMNS && (
        <AlertWarning>{t('TABLE_BUILDER.CAPSULE_COLUMNS_LIMITED')}</AlertWarning>
      )}
      {isTransposed && hasMoreData && <AlertWarning>{t('TABLE_BUILDER.CAPSULE_ROWS_LIMITED')}</AlertWarning>}
      <div
        ref={wrapperRef}
        id={SEEQ_AG_GRID_ID}
        data-testid={'conditionTable'}
        className={classnames('flexFillOverflow overflowAuto', {
          hidden: !showTable && !isInteractiveContent && !headlessRenderMode(),
        })}>
        <AgGridReact
          ref={setAgGridElementWrapper}
          modules={[ClipboardModule, RowGroupingModule]}
          rowSelection="multiple"
          domLayout={getDomLayout(isInteractiveContent)}
          headerHeight={headerHeight}
          rowData={data}
          columnDefs={columnDefs}
          defaultColDef={DEFAULT_COL_DEF}
          autoGroupColumnDef={{ ...AUTO_GROUP_COL_DEF, headerName: t('TABLE_BUILDER.ENABLE_GROUPING_BAR') }}
          rowGroupPanelShow={isTransposed && autoGroupColumn ? 'always' : 'never'}
          onColumnResized={isInteractiveContent ? undefined : onColumnResized}
          rowDragManaged={true}
          animateRows={!headlessRenderMode()}
          suppressColumnVirtualisation={true}
          suppressDragLeaveHidesColumns={true}
          suppressFieldDotNotation={true}
          onColumnMoved={(event) => onColumnMoved(event, props.moveColumn)}
          onRowDragEnd={(event) => {
            onRowDragEnd(
              event.node,
              getConditionTableDragColumns(conditionColumns, isTransposed),
              props.moveColumn,
              rowNodeOutsideGridRef,
            );
          }}
          onRowDragLeave={(event) => {
            rowNodeOutsideGridRef.current = event.node;
          }}
          onDragStopped={() => {
            if (rowNodeOutsideGridRef.current) {
              onRowDragEnd(
                rowNodeOutsideGridRef.current,
                getConditionTableDragColumns(conditionColumns, isTransposed),
                props.moveColumn,
                rowNodeOutsideGridRef,
              );
            }
          }}
          rowDragText={(params) =>
            (isTransposed
              ? params.rowNode?.data.conditionTableHeaderColumn.textHeaderProps.textValue
              : params.rowNode?.data.column0?.textHeaderProps.textValue) ?? ''
          }
          rowHeight={DEFAULT_TABLE_ROW_HEIGHT}
          onGridReady={gridReady}
          overlayNoRowsTemplate="<div></div>"
          getRowId={getRowId}
          groupAllowUnbalanced={true} // Needed to ensure UOM row is not grouped
          functionsReadOnly={isInteractiveContent} // Re-enable as part of CRAB-40362
          onColumnRowGroupChanged={(event) => {
            // If table data does not match up it means page is rehydrating. In fast-follow mode this can cause
            // grouping to be turned off as the data is loading.
            if (conditionColumns.rows.every((r) => tableData.headers.some((h) => r.key === h.key))) {
              handleRowGroupChanged(event);
              autoSizeHelper();
            }
          }}
          onRowGroupOpened={(event) => {
            handleRowGroupOpened(event);
            autoSizeHelper();
          }}
          isGroupOpenByDefault={(params) => {
            if (!params?.rowNode.key) {
              return false;
            }
            return rowGroupPaths.includes(getFullGroupedNodePath(params.rowNode));
          }}
          suppressAggFuncInHeader={true}
          aggFuncs={{
            stdDev: standardDeviationAggFunc,
            range: rangeAggFunc,
            first: firstNonEmptyValueAggFunc,
            last: lastNonEmptyValueAggFunc,
          }}
        />
      </div>
    </>
  );
};
